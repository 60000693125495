@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .editor-btn {
        @apply flex justify-center items-center text-slate-700 rounded-lg w-8 aspect-square;
    }

    .active-editor-btn {
        @apply text-slate-800 bg-slate-200;
    }

    .sidebar-wrapper {
        @apply w-full pb-[30px] relative top-[80px];
        height: calc(100% - 80px);
    }

    .navLink.active + span {
        @apply left-[1rem] opacity-100;
    }

    .banner-title {
        @apply drop-shadow;
        font-size: min(10vw, 5rem);
    }

    .banner-title span {
        font-size: min(16vw, 6rem);
    }

    .section-title {
        @apply text-black text-center mb-8 pt-8 md:text-5xl text-3xl relative font-MontserratArmSemiBold;
    }

    .footer-element-container li span {
        @apply bg-dark-gray;
    }

    .element-right {
        @apply after:content-[''] after:absolute after:-mx-5 after:right-[-1.25rem] after:bg-primary after:w-[30px] after:h-[25px];
    }

    .element-left {
        @apply relative lg:min-w-[100px] min-w-[50px] mb-20 before:content-[''] before:absolute
        before:lg:w-[100px] before:w-[50px] before:h-[25px]
        before:lg:top-[45px] before:md:top-[45px] before:top-[40px] before:bg-primary before:left-0 before:-mx-5;
    }

    .btn {
        @apply font-montserrat text-base text-black pl-8 pr-0 py-3 inline-flex items-center gap-4 whitespace-nowrap;
    }

    .btn-primary {
        @apply px-8 bg-white60;
    }
}

:root, .root, body {
    min-height: 100%;
    font-size: 16px;
}

html {
    @apply scroll-smooth;
}

@media (max-width: 639px) {
    :root, .root, body {
        font-size: 15px;
    }
}

blockquote {
    quotes: "“" "”";
    position: relative;
    text-indent: 3rem;
}

blockquote::before {
    content: open-quote;
    text-indent: 0;
    line-height: 3rem;
    font-size: 7rem;
    position: absolute;
    left: 0;
    color: #cbcbcb;
    font-family: Playfair Display SC, serif;
}

/* Carousel slick-track*/

.slick-slider .slick-arrow {
    display: none !important;
}

.slick-slider .slick-prev {
    @apply right-8;
}

.slick-slider .slick-next {
    @apply right-0;
}

.slick-slider .slick-track {
    @apply py-8;
}

.slick-slider img {
    @apply select-none pointer-events-none px-1;
}

.slick-slider .slick-slide {
    @apply relative opacity-50;
    transition: all .3s ease;
}

.slick-slider .slick-slide.slick-center {
    @apply scale-125 z-10 opacity-100;
}

.slick-slider .slick-current {
    @apply scale-125 z-10 opacity-100;
}

.slick-slider .slick-list {
    @apply pl-[40px];
}

.reviews.slick-slider .slick-slide {
    @apply opacity-100 h-auto flex;
}

.reviews.slick-slider .slick-track {
    @apply flex items-stretch;
}

.rdw-editor-toolbar {
    border-radius: 0.5rem !important;
    border-color: #000 !important;
    @apply bg-white text-black mt-3 px-5 py-4 block outline-none;
}

.wysiwyg div, .wysiwyg p, .wysiwyg strong {
    min-height: 1rem;
}

.single-image-container * {
    pointer-events: all;
}

.z-60 { z-index: 60 }

.product-card-desc p {
    @apply py-2;
}

.brand-hover .brand-logo{
    filter: brightness(0) invert(1);
}

.brand-hover:hover .brand-logo{
    filter: none;
}
.user-drag-none {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.font-MontserratArmSemiBold {
    font-weight: 700;
}


