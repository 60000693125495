@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@400;700&family=Kaushan+Script&family=Playfair+Display+SC:wght@400;700&display=swap');

@font-face {
    font-family: 'Montserratarm-Regular';
    src: url('./fonts/Montserratarm-Regular/Montserratarm-Regular.eot?#iefix') format('embedded-opentype'),  url('./fonts/Montserratarm-Regular/Montserratarm-Regular.otf')  format('opentype'),
    url('./fonts/Montserratarm-Regular/Montserratarm-Regular.woff') format('woff'), url('./fonts/Montserratarm-Regular/Montserratarm-Regular.ttf')  format('truetype'), url('./fonts/Montserratarm-Regular/Montserratarm-Regular.svg#Montserratarm-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Montserratarm-SemiBold';
    src: url('./fonts/Montserratarm-SemiBold/Montserratarm-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Montserratarm-SemiBold/Montserratarm-SemiBold.otf')  format('opentype'),
    url('./fonts/Montserratarm-SemiBold/Montserratarm-SemiBold.woff') format('woff'),
    url('./fonts/Montserratarm-SemiBold/Montserratarm-SemiBold.ttf')  format('truetype'),
    url('./fonts/Montserratarm-SemiBold/Montserratarm-SemiBold.svg#Montserratarm-Regular') format('svg');
    font-weight: 700;
}